import {toBase64, fromBase64} from '@cosmjs/encoding';
import {
  ActionIcon,
  Alert,
  Button,
  CopyButton,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import {Form, Link, useSearchParams, useSubmit, useNavigation} from '@remix-run/react';
import {IconArrowLeft, IconCheck, IconCopy} from '@tabler/icons';
import {useCallback, useMemo, useState} from 'react';
import {createSearchParams} from 'react-router-dom';
import {ChainSelector} from '~/components/ChainSelector';
import {useWallet} from '~/features/wallet';
import {json, useActionData} from '~/features/serialization';
import {generateMetaTags} from '~/features/seo';
import {URLParams} from '~/utils/URLParams';
import {findSignerByAddress, getSignerById} from '~/models/signer.server';
import {createSignerSession} from '~/session.server';
import {experimentalAdr36Verify} from '~/address.server';
import * as f from '~/utils/format';
import type {ActionArgs, MetaFunction} from '@remix-run/node';
import type {StdTx} from '@cosmjs/amino';
import type {KeplrExtensionWallet} from '~/features/wallet/keplr/extension/KeplrExtensionWallet';
import type {PubKey} from '@keplr-wallet/types';
import {PageExceptions} from './app/$policyUuid/index.boundaries';

export async function action({request}: ActionArgs) {
  const requestText = await request.text();
  const urlParams = new URLParams(`http://host?${requestText}`);

  const signature = urlParams.getString('signature');
  const redirectTo = urlParams.getString('redirectTo');

  if (typeof signature !== 'string' || signature.length === 0) {
    return json({errors: {signature: 'Signature is required'}}, {status: 400});
  }

  const stdTx = JSON.parse(signature).value as StdTx;
  const signerAddress = stdTx.msg[0].value.signer;
  const data = stdTx.msg[0].value.data;
  const dataUint8Array = fromBase64(data);
  const msgString = new TextDecoder().decode(dataUint8Array);
  const msg = JSON.parse(msgString) as {message: string; timestamp: number};
  const loginWindowHours = 24; // don't allow login if timestamp in signed message is older than 24 hours
  if (msg.timestamp + 1000 * 60 * 60 * loginWindowHours < Date.now()) {
    return json({errors: {signature: 'Timestamp is to old.'}}, {status: 400});
  }

  try {
    const isValidSignature = await experimentalAdr36Verify(stdTx);

    if (!isValidSignature) {
      return json({errors: {signature: 'Invalid signature'}}, {status: 500});
    }
  } catch (error) {
    console.log(error);
    return json({errors: {signature: 'Error'}, error}, {status: 500});
  }

  const signer = await findSignerByAddress(signerAddress);

  if (!signer) {
    return json({errors: {signature: 'This wallet is not signer of any account.'}}, {status: 401});
  }

  const account = (await getSignerById(signer.id))?.account;

  if (!account) {
    return json({errors: {signature: 'Not found'}}, {status: 401});
  }

  if (account.disabled) {
    return json({errors: {signature: PageExceptions.ACCOUNT_IS_DISABLED_ERROR}}, {status: 403});
  }

  return createSignerSession({
    request,
    signerId: signer.id,
    redirectTo: redirectTo || '/app',
  });
}

// TODO this should probably be moved to a server-side function
const composeSignedMsg = (signer: string, dataBase64: string, pubKey: PubKey, signature: string) => {
  return {
    type: 'cosmos-sdk/StdTx',
    value: {
      msg: [
        {
          type: 'sign/MsgSignData',
          value: {
            signer: signer,
            data: dataBase64,
          },
        },
      ],
      fee: {amount: [], gas: '0'},
      signatures: [
        {
          pub_key: pubKey,
          signature: signature,
        },
      ],
      memo: '',
    },
  };
};

export const meta: MetaFunction = () => {
  return generateMetaTags({
    title: 'Login',
  });
};

export default function LoginPage() {
  const theme = useMantineTheme();
  const {walletStatus, connect, address, currentChainRecord, wallets} = useWallet();
  const [searchParams] = useSearchParams();
  const submit = useSubmit();
  // get where the user is coming from. If not specified, redirect to /app
  const redirectTo = searchParams.get('redirectTo') || '/app';
  const actionData = useActionData<typeof action>();
  const [signLoading, setSignLoading] = useState(false);
  const {state} = useNavigation();
  const chainId = useMemo(() => currentChainRecord?.chain.chain_id, [currentChainRecord]);

  const getSignedMsg = useCallback(async () => {
    const keplrWallet = wallets[0];
    if (!chainId || !address || !keplrWallet) {
      console.error('Missing chainId, address or keplrWallet');
      return;
    }
    // TODO upgrade @cosmos-kit to version 1.0.X. Check how that works and write proper type here
    // note: not upgrading before we have first working version of the app so we don't break something and get stuck
    // TODO receive data to be signed from server

    const msgToSign = JSON.stringify({message: `I am the owner of ${address}.`, timestamp: Date.now()});
    const signatureResp = await (keplrWallet as KeplrExtensionWallet).signArbitrary(chainId, address, msgToSign);
    if (!signatureResp) {
      return;
    }

    // Message to be signed: string -> Uint8Array -> base64
    const dataUint8Array = new TextEncoder().encode(msgToSign);
    const dataBase64 = toBase64(dataUint8Array);

    // Create signature message
    return JSON.stringify(composeSignedMsg(address, dataBase64, signatureResp.pub_key, signatureResp.signature));
  }, [address, chainId, wallets]);

  async function handleSubmit() {
    setSignLoading(true);
    try {
      const signatureMsg = await getSignedMsg();

      if (!signatureMsg) {
        console.error("Can't create signature message");
        return;
      }

      const params = createSearchParams({
        signature: signatureMsg,
        redirectTo: redirectTo,
      });

      submit(params, {action: '/login', method: 'POST'});
    } catch (error) {
      console.log('Error while submitting login form');
    }
    setSignLoading(false);
  }

  return (
    <Stack
      spacing="xs"
      style={{
        width: 420,
        margin: '80px auto 0 auto',
        padding: theme.spacing.xl,
        backgroundColor: theme.other.getBgColor(theme, 'bg-1'),
        border: `1px solid ${theme.other.getBorderColor(theme, 'border-2')}`,
      }}
    >
      <Text size="sm" color="dimmed" mt={0}>
        Smart Delegation App
      </Text>
      <Group mb="md">
        <Link to="/" style={{display: 'flex'}}>
          <ThemeIcon variant="outline" size={20}>
            <IconArrowLeft />
          </ThemeIcon>
        </Link>
        <Title order={2}>Login</Title>
      </Group>

      <ChainSelector
        size="lg"
        connectOnChange={false}
        sx={theme => ({input: {backgroundColor: theme.other.getBgColor(theme, 'bg-2')}})}
      />
      {walletStatus === 'Connected' && (
        <Group
          spacing={6}
          position="apart"
          style={{
            height: 50,
            paddingLeft: theme.spacing.sm,
            paddingRight: theme.spacing.sm,
            backgroundColor: theme.other.getBgColor(theme, 'bg-2'),
            fontSize: theme.fontSizes.sm,
          }}
        >
          <Text span color="dimmed" weight={500}>
            {f.truncate(address || '', 32, 3)}
          </Text>
          <CopyButton value={address || ''} timeout={2000}>
            {({copied, copy}) => (
              <Tooltip label={copied ? 'Copied' : 'Copy address'} withArrow position="top">
                <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy} variant="light">
                  {copied ? (
                    <IconCheck size={16} stroke={1.5} />
                  ) : (
                    <IconCopy size={16} stroke={1.5} color={theme.other.getTextColor(theme, 'text-2')} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Group>
      )}

      <Form method="POST">
        <Stack spacing="xs">
          {actionData?.errors?.signature && <Alert color="red">{actionData.errors.signature}</Alert>}

          <input type="hidden" name="redirectTo" value={redirectTo} />
          {walletStatus !== 'Connected' ? (
            <Button size="lg" disabled={!connect || !chainId} onClick={connect}>
              Connect Wallet
            </Button>
          ) : (
            <Button
              size="lg"
              onClick={handleSubmit}
              disabled={walletStatus !== 'Connected' || !chainId || !address}
              loading={signLoading || state !== 'idle'}
            >
              Log in
            </Button>
          )}
        </Stack>
      </Form>
    </Stack>
  );
}

import {useMemo, forwardRef} from 'react';
import {Avatar, Select, Text, Group, Image} from '@mantine/core';
import {IconChevronDown, IconBox} from '@tabler/icons';
import {useWallet} from '~/features/wallet';
import type {DefaultProps, Selectors, MantineSize, SelectItemProps} from '@mantine/core';
import type {ChainSelectorStylesParams} from './ChainSelector.styles';
import useStyles from './ChainSelector.styles';

const iconSizes: Record<MantineSize, number> = {
  xs: 16,
  sm: 18,
  md: 20,
  lg: 24,
  xl: 28,
};

type ChainSelectorStylesNames = Selectors<typeof useStyles>;

interface ChainSelectorProps extends DefaultProps<ChainSelectorStylesNames, ChainSelectorStylesParams> {
  size?: MantineSize;
  connectOnChange?: boolean;
}

interface ItemProps extends SelectItemProps {
  size: MantineSize;
  icon: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({icon, label, description, size, ...others}: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={icon} size={iconSizes[size]} />
        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  )
);

SelectItem.displayName = 'ChainSelectItem';

export function ChainSelector({
  styles,
  unstyled,
  className,
  classNames,
  size = 'md',
  connectOnChange = true,
  ...others
}: ChainSelectorProps) {
  const {classes, theme} = useStyles(undefined, {name: 'ChainSelector', styles, classNames, unstyled});

  const {connect, currentChainName, chainRecords, getChainLogo, setCurrentChain} = useWallet();

  const chainOptions = useMemo(
    () =>
      chainRecords.map(chainRecord => {
        return {
          label: chainRecord?.chain.pretty_name,
          value: chainRecord?.name,
          icon: getChainLogo(chainRecord.name),
          size,
          group: chainRecord?.chain.network_type === 'testnet' ? 'Testnets' : 'Mainnets',
        };
      }),
    [chainRecords, getChainLogo, size]
  );

  const onChainChange = async (selectedChainName: string | null) => {
    setCurrentChain(selectedChainName ?? undefined);
    if (connectOnChange) {
      await connect();
    }
  };

  return (
    <Select
      className={classes.root}
      size={size}
      searchable
      value={currentChainName}
      data={chainOptions}
      onChange={onChainChange}
      placeholder="Select chain"
      itemComponent={SelectItem}
      rightSection={<IconChevronDown size={iconSizes[size]} />}
      rightSectionWidth={2.1 * iconSizes[size]}
      icon={
        <Image
          src={getChainLogo(currentChainName)}
          alt={currentChainName ? `${currentChainName} Logo` : ''}
          width={iconSizes[size]}
          height={iconSizes[size]}
          withPlaceholder
          placeholder={
            <IconBox
              color={theme.other.getTextColor(theme, 'text-4')}
              strokeWidth={2}
              style={{backgroundColor: theme.other.getBgColor(theme, 'bg-1')}}
            />
          }
        />
      }
      {...others}
    />
  );
}
